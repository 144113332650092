html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.multi-select {
  /*--rmsc-main: rgb(60, 60, 60) !important;*/
  /*--rmsc-hover: rgb(60, 60, 60) !important;*/
  /*--rmsc-selected: rgb(60, 60, 60) !important;*/
  /*--rmsc-border: #ccc !important;*/
  /*--rmsc-gray: #aaa !important;*/
  /*--rmsc-bg: rgb(80, 80, 80) !important;*/
  /*--rmsc-p: 10px !important; !* Spacing *!*/
  /*--rmsc-radius: 6px !important; !* Radius *!*/
  /*--rmsc-h: 40px !important; !* Height *!*/
}

.dropdown-container {
  z-index: 999;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.rmdp-container {
  .input_range_asdasd {
    background-color: transparent;
    color: var(--blundee_tu-rkiz);
    border: none;
    min-width: auto;
    margin-left: 6px;
    font-size: 13pt;
    font-weight: bold;
    height: 35px;
    padding: 0;
    width: 200px;

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    &:active, &:focus {
      outline: none;
      border: none;
    }
  }
}
